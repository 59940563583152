
import { defineComponent, PropType } from 'vue';
import { useOpenable } from '@/shared/composables/useOpenable';
import HeaderSearch from './HeaderSearch.vue';
import HeaderMenu from './HeaderMenu.vue';
import HeaderCart from './HeaderCart.vue';
import { HEADER_COLOR, CART_COLOR } from '@/shared/constants/header';
import { useVersions } from '@/shared/composables/useVersions';

const ORANGE = 'orange';
const GRAY = 'gray';

export default defineComponent({
  name: 'MainHeader',
  props: {
    variant: {
      type: String as PropType<keyof typeof HEADER_COLOR>,
      default: 'default',
    },
  },
  components: {
    HeaderSearch,
    HeaderMenu,
    HeaderCart,
  },
  setup() {
    const { isLm, isMarkets } = useVersions();

    const { isOpen, toggle, close } = useOpenable();

    return { isOpen, toggle, close, HEADER_COLOR, CART_COLOR, ORANGE, GRAY, isLm, isMarkets };
  },
});
