import './root/main.css';
import { createApp } from 'vue';
import SetupCalendar from 'v-calendar';
import { globalComponents } from '@/shared/components/BaseComponents';
import App from './root/App.vue';
import './root/registerServiceWorker';
import router from './root/router';
import { i18n } from './root/i18n';
import { composition } from './root/composition';
import { directives } from './root/directives';

createApp(App)
  .use(composition)
  .use(directives)
  .use(globalComponents)
  .use(i18n)
  .use(router)
  .use(SetupCalendar, {})
  .mount('#app');
