import { Ref, ref, watch } from 'vue';
import isEqual from 'lodash/isEqual';
import { createSharedComposable } from '@vueuse/core';
import { CartProductWithId, Attachment } from '@/shared/interfaces/cart';
import { useGlobalState } from '@/shared/composables/useGlobalState';
import { formatApiDate } from '@/shared/utils/formatDate';

const CART_PRODUCTS_KEY = 'cart';

interface CartProductIds {
  productId: string;
  attachmentsIds?: number[];
}

interface UseCart {
  addToCart: (values: CartProductWithId) => void;
  updateCart: (values: CartProductWithId[]) => void;
  resetDates: () => void;
  deleteCart: () => void;
  cart: Ref<CartProductWithId[]>;
  count: Ref<number>;
  cartProductIds: Ref<CartProductIds[]>;
}

// Helpers
const formatRange = (value: CartProductWithId) => ({
  ...value,
  range: {
    start: formatApiDate(new Date(value.range.start)),
    end: formatApiDate(new Date(value.range.end)),
  },
});

const isSimilar = (val1: CartProductWithId, val2: CartProductWithId) => {
  const value1 = formatRange(JSON.parse(JSON.stringify(val1)));
  const value2 = formatRange(JSON.parse(JSON.stringify(val2)));

  const isSameProducts =
    value1.id === value2.id &&
    isEqual(value1.range, value2.range) &&
    value1.deliveryAddress === value2.deliveryAddress;

  return isSameProducts;
};

const updateSimilar = (currValue: CartProductWithId, newValue: CartProductWithId) => ({
  ...currValue,
  quantity: currValue.quantity + newValue.quantity,
  attachments: [...currValue.attachments, ...newValue.attachments],
});

const calculateCartItems = (cart: CartProductWithId[]): number => {
  return cart.reduce((prev, curr) => prev + curr.quantity + curr.attachments.length, 0);
};

const getProductsIds = (cart: CartProductWithId[]): CartProductIds[] =>
  cart.map((item: CartProductWithId) =>
    item.attachments.length
      ? { productId: item.id, attachmentsIds: item.attachments.map((att) => att.id) }
      : { productId: item.id }
  );

const isOldDate = (date: Date | string, today: Date) =>
  new Date(date).getTime() - today.getTime() < 0;

const resetAttachmentsDates = (attachments: Attachment[], today: Date) =>
  attachments.map((attachment) => {
    switch (true) {
      case isOldDate(attachment.range.end, today):
        return { ...attachment, range: { start: new Date(today), end: new Date(today) } };
      case isOldDate(attachment.range.start, today):
        return { ...attachment, range: { ...attachment.range, start: new Date(today) } };
      default:
        return attachment;
    }
  });

const resetRange = (product: CartProductWithId, today: Date) => {
  return {
    ...product,
    range: { start: new Date(today), end: new Date(today) },
    attachments: resetAttachmentsDates(product.attachments, today),
  };
};

const resetStart = (product: CartProductWithId, today: Date) => ({
  ...product,
  range: { ...product.range, start: new Date(today) },
  attachments: resetAttachmentsDates(product.attachments, today),
});

const resetAtt = (product: CartProductWithId, today: Date) => ({
  ...product,
  attachments: resetAttachmentsDates(product.attachments, today),
});

const resetProductsDates = (product: CartProductWithId, today: Date) => {
  switch (true) {
    case isOldDate(product.range.end, today):
      return resetRange(product, today);
    case isOldDate(product.range.start, today):
      return resetStart(product, today);
    default:
      return resetAtt(product, today);
  }
};

// Composable
const useCart = (): UseCart => {
  const cart = useGlobalState<CartProductWithId[]>(CART_PRODUCTS_KEY, []);
  const count = ref(calculateCartItems(cart.value));
  const cartProductIds = ref(getProductsIds(cart.value));

  const deleteCart = () => {
    cart.value = [];
  };

  const updateCart = (values: CartProductWithId[]) => {
    cart.value = values;
  };

  const resetDates = () => {
    const today = new Date();
    today.setHours(0, 0, 0);
    cart.value = cart.value.map((product) => {
      return resetProductsDates(product, today);
    });
  };

  const addToCart = (values: CartProductWithId) => {
    const index = cart.value.findIndex((item: CartProductWithId) => isSimilar(item, values));

    if (index + 1) cart.value[index] = updateSimilar(cart.value[index], values);
    else cart.value = [...(cart.value || []), values];
  };

  watch(
    cart,
    (newCart) => {
      count.value = calculateCartItems(newCart);
      cartProductIds.value = getProductsIds(newCart);
    },
    { deep: true }
  );

  return { addToCart, cart, count, cartProductIds, updateCart, resetDates, deleteCart };
};

export const useSharedCart = createSharedComposable(useCart);
