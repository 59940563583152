import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37c6a4b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }
const _hoisted_2 = { class: "menu-close" }
const _hoisted_3 = { class: "menu-navbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_HeaderLink = _resolveComponent("HeaderLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", _hoisted_2, [
      _createVNode(_component_BaseIcon, {
        icon: "close",
        onClick: _ctx.onClose
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("nav", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
        return (_openBlock(), _createBlock(_component_HeaderLink, {
          key: link.term,
          path: link.path,
          external: link.external,
          onClick: _ctx.onClose,
          class: "menu-link"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseHeading, { size: 2 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t(`${link.term}`)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["path", "external", "onClick"]))
      }), 128))
    ])
  ]))
}