
import { computed, defineComponent } from 'vue';
import { useModelWrapper } from '@/shared/composables/useModelWrapper';

export default defineComponent({
  props: {
    min: { type: Number, default: 0 },
    max: { type: Number, default: 999 },
    modelValue: { type: Number, required: true },
    disabled: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const value = useModelWrapper(props, emit);

    const decrease = () => {
      if (Number.isNaN(value.value) || value.value <= props.min) return;
      value.value -= 1;
    };
    const increase = () => {
      if (Number.isNaN(value.value) || value.value >= props.max) return;
      value.value += 1;
    };
    const disabledDecrease = computed(() => value.value <= props.min);
    const disabledIncrease = computed(() => value.value >= props.max);

    const validateNumber = (event: KeyboardEvent) => {
      const eventValue = (event.target as HTMLInputElement)?.value;

      // Allowed navigation keys and prevent values like 00
      if (
        !(
          event.code === 'ArrowLeft' ||
          event.code === 'ArrowRight' ||
          event.code === 'ArrowUp' ||
          event.code === 'ArrowDown' ||
          event.code === 'Delete' ||
          event.code === 'Backspace' ||
          event.code === 'Tab'
        ) &&
        (eventValue + event.key).search(/^[0-9]*[1-9][0-9]*$/) === -1
      ) {
        event.preventDefault();
      }
    };

    return {
      value,
      decrease,
      increase,
      validateNumber,
      disabledDecrease,
      disabledIncrease,
    };
  },
});
