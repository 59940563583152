import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-007d8e50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseButton, {
      type: "button",
      variant: "text",
      size: 0,
      class: "button",
      title: "Decrement",
      disabled: _ctx.disabled || _ctx.disabledDecrease,
      onClick: _ctx.decrease
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseIcon, {
          icon: "stepper_minus",
          class: "w-2"
        })
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]),
    _createVNode(_component_BaseInput, _mergeProps(_ctx.$attrs, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      modelModifiers: { number: true },
      type: "number",
      min: _ctx.min,
      max: _ctx.max,
      class: "input",
      size: 0,
      variant: "none",
      disabled: _ctx.disabled,
      onKeydown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateNumber($event)))
    }), null, 16, ["modelValue", "min", "max", "disabled"]),
    _createVNode(_component_BaseButton, {
      type: "button",
      variant: "text",
      size: 0,
      class: "button",
      title: "Increment",
      disabled: _ctx.disabled || _ctx.disabledIncrease,
      onClick: _ctx.increase
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseIcon, {
          icon: "stepper_plus",
          class: "w-2"
        })
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}