import { Ref, ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { StorageKey } from '@/shared/constants/injectables';

const DISCLAMER_MODAL_KEY = 'disclaimerModal';

interface UseDisclaimerModal {
  hasShown: Ref<boolean>;
  setHasShown: () => void;
  removeHasShown: () => void;
}

export const useDisclaimerModal = (): UseDisclaimerModal => {
  const storage = injectStrict(StorageKey);
  const hasShown = ref(false);

  const setHasShown = () => storage.set(DISCLAMER_MODAL_KEY, 'true');
  const removeHasShown = () => storage.remove(DISCLAMER_MODAL_KEY);

  if (storage.get(DISCLAMER_MODAL_KEY) === 'true') {
    hasShown.value = true;
  }

  return { hasShown, setHasShown, removeHasShown };
};
