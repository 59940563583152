import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-838acb3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    opened: _ctx.isOpen,
    class: "modal-container"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BaseHeading, {
          size: 5,
          class: "modal-title"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('disclaimer_title')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseText, { class: "modal-text" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('disclaimer_text')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_BaseButton, { onClick: _ctx.onConfirm }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('disclaimer_btn')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["opened"]))
}