import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { routes } from '@/shared/constants/routes';

const routeConfig: Array<RouteRecordRaw> = [
  {
    ...routes.home,
    component: () => import('@/pages/Home/HomeWrapper.vue'),
  },
  {
    ...routes.productsList,
    component: () => import('@/pages/ProductsList/ProductsList.vue'),
  },
  {
    ...routes.productsCategory,
    path: routes.productsCategory.path(':id'),
    component: () => import('@/pages/ProductsList/ProductsList.vue'),
  },
  {
    ...routes.productDetails,
    path: routes.productDetails.path(':id'),
    component: () => import('@/pages/ProductDetails/ProductDetailsWrapper.vue'),
  },
  {
    ...routes.productAddToCart,
    path: routes.productAddToCart.path(':id'),
    component: () => import('@/pages/ProductAddToCart/ProductAddToCart.vue'),
  },
  {
    ...routes.newProducts,
    path: routes.newProducts.path(':id'),
    component: () => import('@/pages/NewProducts/NewProducts.vue'),
  },
  {
    ...routes.cart,
    path: routes.cart.path,
    component: () => import('@/pages/Cart/Cart.vue'),
  },
  {
    ...routes.legal,
    component: () => import('@/pages/Legal/Legal.vue'),
  },
  {
    ...routes.search,
    component: () => import('@/pages/Search/Search.vue'),
  },
  {
    ...routes.empty,
    component: () => import('@/pages/Empty.vue'),
  },
  {
    ...routes.error,
    component: () => import('@/pages/Error.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routeConfig,
  scrollBehavior: (to) => {
    if (to.name === routes.productsCategory.name) {
      return { el: '#filters', top: 0, behavior: 'smooth' };
    }
    return { top: 0, behavior: 'smooth' };
  },
});

export default router;
