
import { defineComponent, PropType, computed } from 'vue';
import { mergeClasses } from '@/shared/utils/mergeClasses';
import { BaseInputStyles, colors, sizes, variants } from '@/shared/constants/baseInputStyles';

type VariantTypes = keyof typeof variants;
type ColorTypes = keyof typeof colors;

export default defineComponent({
  name: 'BaseInput',
  props: {
    variant: {
      type: String as PropType<VariantTypes>,
      default: variants.primary,
      validator: (value: string) => {
        return Object.keys(variants).includes(value);
      },
    },
    color: {
      type: String as PropType<ColorTypes>,
      default: colors.default,
      validator: (value: string) => {
        return Object.keys(colors).includes(value);
      },
    },
    size: {
      type: Number,
      default: sizes['1'],
      validator: (value: number) => {
        return Object.values(sizes).includes(value);
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const classes = computed(() => {
      const { variant, color, size } = props;
      return mergeClasses(BaseInputStyles, { variant, color, size });
    });
    return { classes };
  },
});
