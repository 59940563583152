import { useI18n } from 'vue-i18n';

export type TranslationFun = (key?: string, vars?: Record<string, unknown>) => string;

export interface UseTranslationsType {
  t: TranslationFun;
}

export const useTranslations = (): UseTranslationsType => {
  const { t } = useI18n();
  const proxy = (key?: string, vars: Record<string, unknown> = {}) => t(key ? t(key, vars) : '');
  return { t: proxy };
};
