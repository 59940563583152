
import { defineComponent, PropType } from 'vue';
import Header from './Header/Header.vue';
import Footer from './Footer/Footer.vue';
import Disclaimer from './Disclaimer/Disclaimer.vue';
import { HEADER_COLOR } from '@/shared/constants/header';
import { useVersions } from '@/shared/composables/useVersions';

export default defineComponent({
  name: 'Layout',
  props: {
    variant: {
      type: String as PropType<keyof typeof HEADER_COLOR>,
      default: 'default',
      validator: (value: string) => {
        return Object.keys(HEADER_COLOR).includes(value);
      },
    },
  },
  components: {
    Header,
    Footer,
    Disclaimer,
  },
  setup() {
    const { isLm } = useVersions();
    return { isLm };
  },
});
