
import { defineComponent, PropType } from 'vue';
import { Option } from '@/shared/interfaces/options';

export default defineComponent({
  name: 'BaseRadioButtonGroup',
  props: {
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    idDisabled: { type: Boolean, default: false },
    name: { type: String },
  },
  emits: ['update:modelValue'],
});
