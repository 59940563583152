
import { defineComponent } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import HeaderLink from './HeaderLink.vue';
import { headerMenuLinks, headerMenuLinksLM } from '@/shared/constants/header';
import { useVersions } from '@/shared/composables/useVersions';

export default defineComponent({
  name: 'HeaderMenu',
  components: { HeaderLink },
  inheritAttrs: false,
  emits: ['close'],
  setup(_, { emit }) {
    const { t } = useTranslations();
    const onClose = () => {
      emit('close');
    };
    const { isLm } = useVersions();
    const links = isLm ? headerMenuLinksLM : headerMenuLinks;
    return {
      onClose,
      links,
      t,
    };
  },
});
