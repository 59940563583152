
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'BackButton',
  props: {
    to: { type: String, default: '' },
  },
  setup(props) {
    const router = useRouter();
    const onNavigateBack = () => {
      if (!props.to) {
        router.back();
      }
    };
    return { onNavigateBack };
  },
});
