export const BASE_BUTTON_STYLES = {
  variants: {
    primary: {
      base:
        'inline-flex rounded-3 shadow-button leading-none items-center justify-center transition ease-in-out duration-100 disabled:cursor-auto',
      default:
        'bg-gray-1600 text-white font-bold disabled:bg-gray-800 hover:bg-gray-1400 focus:outline-none focus:ring-3 focus:ring-gray-900',
      white: `bg-white text-gray-1600 font-bold disabled:bg-gray-1100 hover:bg-gray-500 focus:outline-none`,
      none: '',
    },
    secondary: {
      base:
        'inline-flex rounded-3 shadow-button leading-none items-center justify-center transition ease-in-out duration-100 disabled:cursor-auto',
      default: `bg-transparent text-gray-1600 font-medium border-2 border-gray-1600 disabled:border-gray-800
           disabled:text-gray-800 hover:text-gray-1100 hover:border-gray-1100
           focus:text-gray-1100 focus:border-gray-1100 focus:outline-none focus:ring-1 focus:ring-gray-1100`,
      white: `bg-transparent text-white font-medium border-2 border-white disabled:opacity-60
           ring-gray-0 hover:ring-1 hover:ring-gray-100 disabled:hover:ring-0 hover:text-gray-100 hover:border-gray-100
           focus:outline-none focus:ring-2 focus:ring-gray-100`,
      none: '',
    },
    text: {
      base: `inline-flex bg-transparent leading-none items-center justify-center transition ease-in-out duration-100
         text-gray-1600 px-0 py-0 disabled:text-gray-800 disabled:cursor-auto hover:text-gray-1100 focus:underline focus:outline-none`,
      default: 'font-medium',
      bold: 'font-bold',
      none: '',
    },
  },
  sizes: {
    0: '',
    1: 'min-h-6 px-1.5 lg:text-18 py-0.5',
    2: 'min-h-6 lg:min-h-7 px-3.5 px-3 text-18 lg:text-20 py-1',
    3: 'min-h-9 lg:min-h-9 px-3 lg:px-3 text-25 lg:text-25 py-1.5',
  },
};

export const variants = { primary: 'primary', secondary: 'secondary', text: 'text', none: 'none' };
export const colors = { default: 'default', none: 'none', bold: 'bold', white: 'white' };
export const sizes = { 0: 0, 1: 1, 2: 2, 3: 3 };
export const components = { button: 'button', a: 'a', 'router-link': 'router-link' };
