
import { defineComponent, PropType, computed } from 'vue';
import { BaseHeadingStyles, levels, SizeTypes } from '@/shared/constants/baseHeadingStyles';

export default defineComponent({
  name: 'BaseHeading',
  props: {
    size: {
      type: Number as PropType<SizeTypes>,
      default: levels['1'],
      validator: (value: number) => {
        return Object.values(levels).includes(value);
      },
    },
    mobileSmaller: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      const { base, sizes, smallMobile, mobile } = BaseHeadingStyles;
      return [base, sizes[props.size], props.mobileSmaller ? smallMobile : mobile];
    });
    return { classes };
  },
});
