import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'button'), {
    to: _ctx.to,
    class: "flex focus:outline-none",
    onClick: _ctx.onNavigateBack
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseIcon, {
        icon: "arrow_thick",
        class: "icon"
      }),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["to", "onClick"]))
}