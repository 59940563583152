
import { defineComponent, watch, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import debounce from 'lodash/debounce';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useOpenable } from '@/shared/composables/useOpenable';
import { headerLinks } from '@/shared/constants/header';
import { routes } from '@/shared/constants/routes';
import { useVersions } from '@/shared/composables/useVersions';
import HeaderLink from './HeaderLink.vue';

const THROTTLE_SEARCH_TIME = 500;
const VARIANT_GRAY = 'gray';

export default defineComponent({
  name: 'HeaderSearch',
  components: { HeaderLink },
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useTranslations();
    const router = useRouter();
    const route = useRoute();
    const { isOpen, toggle, close } = useOpenable();
    const { isMarkets } = useVersions();
    const searchValue = ref('');
    const searchInput = ref<HTMLInputElement>();

    const reset = () => {
      searchValue.value = '';
    };

    const redirect = (q: string) => {
      router.push({ path: routes.search.path, query: { term: q } });
    };

    const search = () => {
      if (!(route.name === routes.search.name)) {
        redirect(searchValue.value);
      }
    };

    const isGray = computed(() => props.variant === VARIANT_GRAY);

    const liveSearch = debounce((value: string) => redirect(value), THROTTLE_SEARCH_TIME);

    watch(searchValue, (newValue) => {
      if (route.name === routes.search.name) {
        liveSearch(newValue);
      }
    });

    watch(route, (newRoute) => {
      if (newRoute.name !== routes.search.name) {
        reset();
        close();
      }
    });

    return {
      isOpen,
      reset,
      search,
      toggle,
      isGray,
      headerLinks,
      searchValue,
      searchInput,
      isMarkets,
      t,
    };
  },
});
