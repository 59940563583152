/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createGlobalState, StorageSerializers, useLocalStorage } from '@vueuse/core';
import { Ref } from 'vue';

const GLOBAL_STATE_KEY = 'global-state';

export const useGlobalState = <T = undefined>(
  storageKey = GLOBAL_STATE_KEY,
  defaultValue: T,
  serializer = StorageSerializers.object,
  storage = useLocalStorage
) =>
  createGlobalState(
    () => storage<T>(storageKey, defaultValue, { serializer }) as Ref<T>
  )();
