import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0710fbfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "id", "checked", "disabled"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.value,
        class: _normalizeClass(["radio-item", { disabled: _ctx.idDisabled }])
      }, [
        _createElementVNode("input", {
          type: "radio",
          class: "radio",
          value: option.value,
          id: `${_ctx.name}.${option.value}`,
          checked: option.value === _ctx.modelValue,
          disabled: _ctx.idDisabled,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
        }, null, 40, _hoisted_1),
        _createElementVNode("label", {
          for: `${_ctx.name}.${option.value}`,
          class: "label"
        }, _toDisplayString(option.label), 9, _hoisted_2)
      ], 2))
    }), 128))
  ]))
}