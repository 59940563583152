import { AxiosResponse } from 'axios';
import { api } from '@/shared/services/api';
import { Category } from '@/shared/interfaces/productDetails';
import { Product } from '@/shared/interfaces/product';

export class ProductsListService {
  async getProductsCategories(): Promise<AxiosResponse<Category[]>> {
    const requestUrl = `/categories`;
    return api.get(requestUrl);
  }

  async getProducts(id?: string, page = 1, pageSize = 20): Promise<AxiosResponse<Product[]>> {
    const pagination = `limit=${pageSize}&offset=${pageSize * (page - 1)}`;
    const requestUrl = id ? `?category=${id}&${pagination}` : `?${pagination}`;
    return api.get(requestUrl);
  }

  async getSearchProducts(
    term?: string,
    page = 1,
    pageSize = 20
  ): Promise<AxiosResponse<Product[]>> {
    const pagination = `limit=${pageSize}&offset=${pageSize * (page - 1)}`;
    const requestUrl = `?search=${term}&${pagination}`;
    return api.get(requestUrl);
  }

  async getNewProducts(page = 1, pageSize = 20): Promise<AxiosResponse<Product[]>> {
    const pagination = `limit=${pageSize}&offset=${pageSize * (page - 1)}`;
    const requestUrl = `?type=new&${pagination}`;
    return api.get(requestUrl);
  }
}
