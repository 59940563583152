import { App } from 'vue';

export const directives = {
  install: (app: App): App => {
    app.directive('focus', {
      mounted(el) {
        el.focus();
      },
    });
    return app;
  },
};
