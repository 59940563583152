export const variants = { primary: 'primary', none: 'none' };
export const colors = { default: 'default', none: 'none' };
export const sizes = { 0: 0, 1: 1 };

export type VariantTypes = keyof typeof variants;

export const BaseInputStyles = {
  variants: {
    primary: {
      base: 'inline-flex w-full rounded-3 transition ease-in-out duration-100',
      default: `bg-gray-200 text-gray-1600 placeholder-gray-800 disabled:text-gray-800 disabled:hover:bg-gray-200
        hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-600`,
      none: '',
    },
    none: {},
  },
  sizes: {
    0: '',
    1: 'h-[4rem] px-1.5 py-1.5 text-16 lg:text-18',
  },
};
