import { differenceInBusinessDays, differenceInMonths } from 'date-fns';
import { i18n } from '@/root/i18n';
import { DateStringsRange } from '@/shared/interfaces/calendar';
import { useVersions } from '@/shared/composables/useVersions';

const { isPl } = useVersions();

const daySeconds = 86400000;

const removeTime = (date: string) => {
  const datObj = new Date(date);
  return new Date(datObj.getFullYear(), datObj.getMonth(), datObj.getDate());
};

export const formatApiDate = (date: Date | string): string => {
  if (typeof date === 'string') return date;
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const formatDate = (date: Date): string => date.toLocaleDateString(i18n.global.locale.value);

export const formatDateString = (date: string): string =>
  new Date(date).toLocaleDateString(i18n.global.locale.value);

export const calculateDaysDifferenceDefault = (range: DateStringsRange): number => {
  return (
    Math.round((removeTime(range.end).getTime() - removeTime(range.start).getTime()) / daySeconds) +
    1
  );
};

export const calculateDaysDifferencePl = (range: DateStringsRange): number =>
  differenceInBusinessDays(new Date(range.end), new Date(range.start)) + 1;

export const calculateDaysDifference = (range: DateStringsRange): number =>
  isPl ? calculateDaysDifferencePl(range) : calculateDaysDifferenceDefault(range);

export const calculateMonthsDifference = (range: DateStringsRange): number =>
  differenceInMonths(new Date(range.end), new Date(range.start));

export const formatRangeValues = (values: DateStringsRange): DateStringsRange => ({
  start: formatDateString(values.start),
  end: formatDateString(values.end),
});

export const formatOpenEndedValues = (values: string, end: string): DateStringsRange => ({
  start: formatDateString(values as string),
  end,
});
