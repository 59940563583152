import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.routes.cart.path,
    class: "relative ml-2 lg:ml-3"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseIcon, {
        icon: "cart",
        class: "w-3.5 lg:w-[3.4rem]",
        alt: "Cart"
      }),
      (_ctx.count)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["cart-count", _ctx.CART_COLOR[_ctx.variant]])
          }, _toDisplayString(_ctx.count), 3))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["to"]))
}