const footerAboutLinksDefault = [
  {
    term: 'footer_about_company',
    path: 'https://www.kurt-koenig.de/das-unternehmen/',
  },
  {
    term: 'footer_about_financial',
    path: 'https://www.kurt-koenig.de/finanzierungsservice/',
  },
  {
    term: 'footer_about_contact',
    path: 'https://www.kurt-koenig.de/kontakt/',
  },
];

const footerAboutLinksPl = [
  {
    term: 'footer_about_company',
    path: 'https://kurt-koenig.com.pl/',
  },
  {
    term: 'footer_about_contact',
    path: 'https://kurt-koenig.com.pl/kontakt,6,pl',
  },
];

const footerAboutLinksLv = [
  {
    term: 'footer_about_company',
    path: 'https://kurt-koenig.lv/',
  },
  {
    term: 'footer_about_contact',
    path: 'https://kurt-koenig.lv/kontakti/',
  },
];

const footerAboutLinksLt = [
  {
    term: 'footer_about_company',
    path: 'http://kurt-koenig.lt/Kurt-Koenig',
  },
  {
    term: 'footer_about_contact',
    path: 'http://kurt-koenig.lt/kontaktai',
  },
];

export const footerAboutLinks = {
  de: footerAboutLinksDefault,
  lm: footerAboutLinksDefault,
  pl: footerAboutLinksPl,
  lv: footerAboutLinksLv,
  lt: footerAboutLinksLt,
};

export const footerSocialLinks = [
  {
    term: 'footer_social_school',
    path: 'https://www.kurt-koenig.de/ghana/',
  },
  {
    term: 'footer_social_bees',
    path: 'https://www.kurt-koenig.de/maschinchen/',
  },
  {
    term: 'footer_social_trees',
    path: 'https://www.kurt-koenig.de/baumpflanzaktion/',
  },
];

export const footerSocialMediaLinks = [
  {
    icon: 'facebook',
    path: 'https://www.facebook.com/kurtkoenigbaumaschinen/',
  },
  {
    icon: 'linkedin',
    path: 'https://www.linkedin.com/company/kurt-koenig-baumaschinen/',
  },
  {
    icon: 'instagram',
    path: 'https://www.instagram.com/kurtkoenig_baumaschinen/?hl=de',
  },
];

export const footerConditionsLinks = [
  {
    term: 'footer_conditions_data',
    path: 'https://www.kurt-koenig.de/datenschutz/',
  },
  {
    term: 'footer_conditions_imprint',
    path: 'https://www.kurt-koenig.de/impressum/',
  },
  {
    term: 'footer_conditions_conditions',
    path: 'https://www.kurt-koenig.de/agb/',
  },
];

const footerLocationsLinksDefault = [
  {
    term: 'Einbeck',
    path: 'https://www.kurt-koenig.de/standorte/einbeck',
  },
  {
    term: 'Göttingen',
    path: 'https://www.kurt-koenig.de/standorte/goettingen',
  },
  {
    term: 'Wolfsburg',
    path: 'https://www.kurt-koenig.de/standorte/wolfsburg',
  },
  {
    term: 'Braunschweig',
    path: 'https://www.kurt-koenig.de/standorte/braunschweig',
  },
  {
    term: 'Hannover',
    path: 'https://www.kurt-koenig.de/standorte/hannover',
  },
  {
    term: 'Gießen',
    path: 'https://www.kurt-koenig.de/standorte/giessen',
  },
  {
    term: 'Hildesheim',
    path: 'https://www.kurt-koenig.de/standorte/hildesheim',
  },
  {
    term: 'Kassel',
    path: 'https://www.kurt-koenig.de/standorte/kassel',
  },
  {
    term: 'Magdeburg',
    path: 'https://www.kurt-koenig.de/standorte/magdeburg',
  },
  {
    term: 'Nordhausen',
    path: 'https://www.kurt-koenig.de/standorte/nordhausen',
  },
  {
    term: 'Sangerhausen',
    path: 'https://www.kurt-koenig.de/standorte/sangerhausen',
  },
];

const footerLocationsLinksPl = [
  {
    term: 'Gdańsk',
    path: 'https://kurt-koenig.com.pl/kontakt,6,pl',
  },
  {
    term: 'Gdynia',
    path: 'https://kurt-koenig.com.pl/kontakt,6,pl',
  },
  {
    term: 'Słupsk',
    path: 'https://kurt-koenig.com.pl/kontakt,6,pl',
  },
  {
    term: 'Grudziądz',
    path: 'https://kurt-koenig.com.pl/kontakt,6,pl',
  },
];

const footerLocationsLinksLv = [
  {
    term: 'Rīga, Hanzas iela',
    path: 'https://kurt-koenig.lv/kontakti/',
  },
  {
    term: 'Rīga, Granīta iela',
    path: 'https://kurt-koenig.lv/kontakti/',
  },
];

const footerLocationsLinksLt = [
  {
    term: 'Vilnius',
    path: 'http://kurt-koenig.lt/kontaktai',
  },
  {
    term: 'Kaunas',
    path: 'http://kurt-koenig.lt/kontaktai',
  },
  {
    term: 'Klaipėda',
    path: 'http://kurt-koenig.lt/kontaktai',
  },
];

export const footerLocationsLinks = {
  de: footerLocationsLinksDefault,
  lm: footerLocationsLinksDefault,
  pl: footerLocationsLinksPl,
  lv: footerLocationsLinksLv,
  lt: footerLocationsLinksLt,
};
