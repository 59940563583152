export const headerMenuLinks = [
  { term: 'navbar_menu_home', path: 'https://www.kurt-koenig.de/', external: true },
  { term: 'navbar_menu_lm', path: 'https://machines.kurt-koenig.de/', external: true },
  {
    term: 'navbar_menu_offers',
    path: 'https://www.kurt-koenig.de/angebote-aktionen/',
    external: true,
  },
  {
    term: 'navbar_menu_company',
    path: 'https://www.kurt-koenig.de/das-unternehmen/',
    external: true,
  },
  {
    term: 'navbar_menu_job',
    path: 'https://kurt-koenig.com/de/karriere',
    external: true,
  },
  { term: 'navbar_menu_contact', path: 'https://www.kurt-koenig.de/kontakt/', external: true },
];

export const headerMenuLinksLM = [
  { term: 'navbar_menu_home', path: 'https://www.kurt-koenig.de/', external: true },
  { term: 'navbar_menu_rental', path: 'https://rental.kurt-koenig.de/', external: true },
  { term: 'navbar_menu_shop', path: 'https://shop.kurt-koenig.de/de_DE/', external: true },
  { term: 'navbar_menu_lm', path: 'https://machines.kurt-koenig.de/', external: true },
  {
    term: 'navbar_menu_offers',
    path: 'https://www.kurt-koenig.de/angebote-aktionen/',
    external: true,
  },
  {
    term: 'navbar_menu_job',
    path: 'https://kurt-koenig.com/de/karriere',
    external: true,
  },
  { term: 'navbar_menu_contact', path: 'https://www.kurt-koenig.de/kontakt/', external: true },
];

export const headerLinks = [
  { term: 'navbar_rent', path: 'https://rental.kurt-koenig.de/', external: true },
  { term: 'navbar_buy', path: 'https://shop.kurt-koenig.de/', external: true },
  { term: 'navbar_service', path: 'https://www.kurt-koenig.de/service/', external: true },
];

export const HEADER_COLOR = {
  default: 'bg-gray-1700',
  orange: 'bg-orange-500',
  gray: 'bg-gray-500',
};

export const CART_COLOR = {
  default: 'border-gray-1700',
  orange: 'border-white',
};
