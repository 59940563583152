import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, {
    class: "error default",
    hover: "",
    placement: _ctx.placement,
    "onOpen:popper": _ctx.open,
    "onClose:popper": _ctx.close
  }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_BaseIcon, {
        icon: "error",
        class: _normalizeClass(_ctx.iconClass)
      }, null, 8, ["class"])
    ]),
    _: 3
  }, 8, ["placement", "onOpen:popper", "onClose:popper"]))
}