
import { defineComponent } from 'vue';
import { useProductImage } from '@/shared/composables/useAssets';

export default defineComponent({
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const source = useProductImage(props.src);
    return {
      source,
    };
  },
});
