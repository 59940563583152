
import { defineComponent, onMounted } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useOpenable } from '@/shared/composables/useOpenable';
import { useDisclaimerModal } from '@/shared/composables/useDisclaimerModal';

export default defineComponent({
  name: 'Disclaimer',
  setup() {
    const { t } = useTranslations();
    const { isOpen, open, close } = useOpenable();
    const { hasShown, setHasShown } = useDisclaimerModal();
    onMounted(() => {
      if (!hasShown.value) {
        open();
      }
    });
    const onConfirm = () => {
      close();
      setHasShown();
    };
    return { t, isOpen, onConfirm };
  },
});
