
import { defineComponent, PropType } from 'vue';
import { BASE_TEXT_SIZES } from '@/shared/constants/baseTextStyles';

export default defineComponent({
  name: 'BaseText',
  props: {
    component: {
      type: String as PropType<string>,
      default: 'span',
    },
    variant: {
      type: String as PropType<keyof typeof BASE_TEXT_SIZES>,
      default: 'default',
      validator: (value: string) => {
        return Object.keys(BASE_TEXT_SIZES).includes(value);
      },
    },
  },
  setup() {
    return { BASE_TEXT_SIZES };
  },
});
