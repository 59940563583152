
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InputGroup',
  props: {
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
});
