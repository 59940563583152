
import { defineComponent, PropType } from 'vue';
import Multiselect from '@vueform/multiselect';
import { useTranslations } from '@/shared/composables/useTranslations';
import { Option } from '@/shared/interfaces/options';

export default defineComponent({
  name: 'BaseDropdownInput',
  components: { Multiselect },
  props: {
    options: {
      type: [Array, Function] as PropType<Option[] | (() => Promise<Option[]>)>,
      required: true,
    },
  },
  emits: ['change'],
  setup(_, { emit }) {
    const { t } = useTranslations();

    const optionChange = (option: unknown) => {
      emit('change', option);
    };

    return {
      optionChange,
      t,
    };
  },
});
