
import { defineComponent, PropType, computed } from 'vue';
import { mergeClasses } from '@/shared/utils/mergeClasses';
import {
  BASE_BUTTON_STYLES,
  variants,
  colors,
  sizes,
  components,
} from '@/shared/constants/baseButtonStyles';

type VariantTypes = keyof typeof variants;
type ColorTypes = keyof typeof colors;
type IsTypes = keyof typeof components;

export default defineComponent({
  name: 'BaseButton',
  props: {
    component: {
      type: String as PropType<IsTypes>,
      default: components.button,
      validator: (value: string) => {
        return Object.keys(components).includes(value);
      },
    },
    variant: {
      type: String as PropType<VariantTypes>,
      default: variants.primary,
      validator: (value: string) => {
        return Object.keys(variants).includes(value);
      },
    },
    color: {
      type: String as PropType<ColorTypes>,
      default: colors.default,
      validator: (value: string) => {
        return Object.keys(colors).includes(value);
      },
    },
    size: {
      type: Number,
      default: sizes['2'],
      validator: (value: number) => {
        return Object.values(sizes).includes(value);
      },
    },
  },
  setup(props) {
    const classes = computed(() => {
      const { variant, color, size } = props;
      return mergeClasses(BASE_BUTTON_STYLES, { variant, color, size });
    });
    const buttonType = computed(() =>
      props.component === components.button ? 'button' : undefined
    );
    return { classes, buttonType };
  },
});
