<template>
  <router-view v-slot="{ Component, route }">
    <Layout :variant="route.meta.headerVariant">
      <transition name="scale" mode="out-in">
        <component :is="Component" :key="route.path"></component>
      </transition>
    </Layout>
  </router-view>
</template>
<script land="ts">
import { defineComponent } from 'vue';
import Layout from '@/shared/components/Layout/Layout.vue';

export default defineComponent({
  name: 'App',
  components: {
    Layout,
  },
});
</script>

<style>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.3s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
