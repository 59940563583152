import { AxiosResponse } from 'axios';
import { api } from '@/shared/services/api';
import { TransformedCatalogRequest } from '@/shared/interfaces/catalogRequest';

export class CatalogRequestService {
  async requestCatalog(values: TransformedCatalogRequest): Promise<AxiosResponse<Response>> {
    const requestUrl = `/request-catalog`;
    return api.post(requestUrl, {
      ...values,
    });
  }
}
