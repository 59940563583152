
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseModal',
  inheritAttrs: false,
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
});
