import { getConfig } from '@/shared/config';

const headerVariant = getConfig().appVersion === 'lm' ? 'gray' : 'orange';

export const routes = {
  home: {
    path: '/',
    name: 'Home',
    meta: {
      headerVariant,
    },
  },
  productsList: {
    path: `/list`,
    name: 'Products list',
  },
  productsCategory: {
    path: (id: string): string => `/list/${id}`,
    name: 'Products category',
  },
  productDetails: {
    path: (id: string): string => `/product/${id}`,
    name: 'Product details',
  },
  productAddToCart: {
    path: (id: string): string => `/product/${id}/precart`,
    name: 'Product add to cart',
  },
  newProducts: {
    path: (id: string): string => `/new/${id}`,
    name: 'New products',
  },
  cart: {
    path: '/cart',
    name: 'Cart',
  },
  legal: {
    path: '/legal',
    name: 'Legal',
  },
  search: {
    path: '/search',
    name: 'Search',
  },
  empty: {
    path: '/empty',
    name: 'No product',
  },
  error: {
    path: '/error',
    name: 'Error',
  },
};
