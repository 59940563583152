import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-865b326c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navbar-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_HeaderSearch = _resolveComponent("HeaderSearch")!
  const _component_HeaderCart = _resolveComponent("HeaderCart")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header", _ctx.HEADER_COLOR[_ctx.variant]])
  }, [
    _createVNode(_component_BaseContainer, { class: "header-container" }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: "/",
          class: "cursor-pointer"
        }, {
          default: _withCtx(() => [
            (_ctx.variant === _ctx.ORANGE)
              ? (_openBlock(), _createBlock(_component_BaseImage, {
                  key: 0,
                  icon: "logo_white",
                  class: "logo",
                  alt: "Logo"
                }))
              : (_ctx.variant === _ctx.GRAY)
                ? (_openBlock(), _createBlock(_component_BaseImage, {
                    key: 1,
                    icon: "logo_black",
                    class: "logo",
                    alt: "Logo"
                  }))
                : (_openBlock(), _createBlock(_component_BaseImage, {
                    key: 2,
                    icon: "logo",
                    class: "logo",
                    alt: "Logo"
                  }))
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_HeaderSearch, { variant: _ctx.variant }, null, 8, ["variant"]),
          (!_ctx.isLm)
            ? (_openBlock(), _createBlock(_component_HeaderCart, {
                key: 0,
                variant: _ctx.variant
              }, null, 8, ["variant"]))
            : _createCommentVNode("", true),
          (!_ctx.isMarkets)
            ? (_openBlock(), _createBlock(_component_BaseIcon, {
                key: 1,
                icon: "menu",
                class: _normalizeClass(["menu-icon", { 'menu-icon-gray': _ctx.variant === _ctx.GRAY }]),
                onClick: _ctx.toggle,
                alt: "Menu"
              }, null, 8, ["class", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    (_ctx.isOpen)
      ? (_openBlock(), _createBlock(_component_HeaderMenu, {
          key: 0,
          onClose: _ctx.close
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}