
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseSwitch',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue'],
});
