export const levels = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 };
export type SizeTypes = keyof typeof levels;

export const BaseHeadingStyles = {
  base: 'font-black',
  smallMobile: 'text-18',
  mobile: 'text-30',
  sizes: {
    1: 'lg:text-64',
    2: 'lg:text-60',
    3: 'lg:text-48',
    4: 'lg:text-45',
    5: 'lg:text-40',
    6: 'lg:text-34',
  },
};
