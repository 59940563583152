<template>
  <footer class="bg-gray-1700">
    <BaseContainer class="container">
      <router-link to="/" class="cursor-pointer">
        <BaseImage icon="logo" class="main-logo" alt="Logo" />
      </router-link>
      <div class="footer-grid">
        <div class="title">
          <BaseText variant="18-20" class="bold-white-text">{{ t('footer_title_part1') }}</BaseText>
          <BaseText variant="18-20" class="bold-white-text">{{ t('footer_title_part2') }}</BaseText>
        </div>
        <div class="about">
          <BaseText variant="18-20" class="bold-white-text">{{ t('footer_about_title') }}</BaseText>
          <div class="links-container">
            <a
              v-for="link in footerAboutLinks[version]"
              :key="link.term"
              :href="link.path"
              class="cursor-pointer mt-2"
            >
              <BaseText variant="15" class="text-white">{{ t(link.term) }}</BaseText>
            </a>
          </div>
        </div>
        <div class="social">
          <BaseText variant="18-20" class="bold-white-text">{{
            t('footer_social_title')
          }}</BaseText>
          <div class="links-container">
            <a
              v-for="link in footerSocialLinks"
              :key="link.term"
              :href="link.path"
              class="cursor-pointer mt-2"
            >
              <BaseText variant="15" class="text-white">{{ t(link.term) }}</BaseText>
            </a>
          </div>
        </div>
        <div class="social-icons">
          <BaseText variant="18-20" class="bold-white-text">{{
            t('footer_social_media_title')
          }}</BaseText>
          <div class="mt-4 flex text-white">
            <a
              v-for="link in footerSocialMediaLinks"
              :key="link.icon"
              :href="link.path"
              class="cursor-pointer mr-2 lg:mr-2.5"
            >
              <BaseIcon :icon="link.icon" :alt="link.icon" class="social-icon" />
            </a>
          </div>
        </div>
        <div class="locations">
          <BaseText variant="18-20" class="bold-white-text">{{
            t('footer_locations_title')
          }}</BaseText>
          <div class="mt-2">
            <a
              v-for="link in footerLocationsLinks[version]"
              :key="link.term"
              :href="link.path"
              class="locations-link"
            >
              <BaseText variant="15" class="text lg:py-1.5">{{ t(link.term) }}</BaseText>
            </a>
          </div>
        </div>
        <div class="conditions">
          <div class="flex w-full flex-col lg:flex-row">
            <BaseText class="conditions-text pt-0.5">
              {{ t('footer_conditions_title', { year: new Date().getFullYear() }) }}
            </BaseText>
            <div>
              <a
                v-for="link in footerConditionsLinks"
                :key="link.term"
                :href="link.path"
                class="conditions-link"
              >
                <BaseText class="conditions-text">{{ t(link.term) }}</BaseText>
              </a>
            </div>
          </div>
          <BaseText class="conditions-statement">
            {{ t('footer_conditions_text') }}
          </BaseText>
        </div>
      </div>
    </BaseContainer>
  </footer>
</template>
<script>
import { defineComponent } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { getConfig } from '@/shared/config';
import {
  footerAboutLinks,
  footerSocialLinks,
  footerLocationsLinks,
  footerSocialMediaLinks,
  footerConditionsLinks,
} from '@/shared/constants/footer';

export default defineComponent({
  name: 'Footer',
  setup() {
    const { t } = useTranslations();
    const version = getConfig().appVersion;

    return {
      t,
      footerAboutLinks,
      version,
      footerSocialLinks,
      footerLocationsLinks,
      footerSocialMediaLinks,
      footerConditionsLinks,
    };
  },
});
</script>
<style scoped>
.container {
  @apply flex flex-col pt-5 pb-7.5;
}

.main-logo {
  @apply w-[210px];
}

.footer-grid {
  @apply grid h-full grid-cols-2 mt-3.5;
}

.footer-grid .title {
  @apply col-start-1 col-end-3 flex flex-col mb-5;
}

.footer-grid .about {
  @apply col-start-1 col-end-2 mb-5;
}

.footer-grid .social {
  @apply col-start-1 col-end-3 order-1 mb-5;
}

.footer-grid .social-icons {
  @apply ml-4;
}

.footer-grid .locations {
  @apply col-start-1 col-end-3 order-1 mb-5;
}

.footer-grid .conditions {
  @apply font-light text-gray-400 col-start-1 col-end-3 order-2;
}

.links-container {
  @apply flex flex-col;
}

.locations-link {
  @apply cursor-pointer w-full inline-block border-b border-gray-900;
}

.locations-link:last-of-type {
  @apply border-none;
}

.locations-link .text {
  @apply inline-block w-full text-white py-1;
}

.social-icon {
  @apply h-[22px];
}

.conditions-link {
  @apply cursor-pointer border-r border-gray-900 px-1;
}

.conditions-link:last-of-type {
  @apply border-none;
}

.conditions-link:first-of-type {
  @apply pl-0;
}

.conditions-text {
  @apply text-gray-900 font-bold text-14;
}

.conditions-statement {
  @apply text-gray-900 font-light mt-2 block leading-snug text-14 max-w-[872px] 2xl:max-w-[1100px];
}

.bold-white-text {
  @apply font-bold text-white leading-tight;
}

@screen lg {
  .container {
    @apply pt-9 pb-13;
  }

  .footer-grid {
    @apply gap-4 grid-rows-3 grid-cols-4;
  }

  .footer-grid .title {
    @apply col-start-1 col-end-2 row-start-1 row-end-2 mb-0;
  }

  .footer-grid .about {
    @apply col-start-2 col-end-3 row-start-1 row-end-2 mb-0;
  }

  .footer-grid .social {
    @apply col-start-3 col-end-4 row-start-1 row-end-2 order-none mb-0;
  }

  .footer-grid .social-icons {
    @apply col-start-3 col-end-4 row-start-2 row-end-3 order-1 ml-0;
  }

  .footer-grid .locations {
    @apply col-start-4 col-end-5 row-start-1 row-end-4 order-none mb-0;
  }

  .footer-grid .conditions {
    @apply col-start-1 col-end-4 row-start-3 row-end-4;
  }

  .conditions-link:first-of-type {
    @apply pl-1;
  }
}
</style>
