import { AxiosResponse } from 'axios';
import { api } from '@/shared/services/api';
import { LmRequest } from '@/shared/interfaces/lmRequest';

export class LmRequestService {
  async requestLm(values: LmRequest): Promise<AxiosResponse<Response>> {
    const requestUrl = `/general-request`;
    return api.post(requestUrl, {
      ...values,
    });
  }
}
