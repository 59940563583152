<template>
  <a v-if="external" :href="path"><slot></slot></a>
  <router-link v-else :to="path"><slot></slot></router-link>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderLink',
  props: {
    path: { type: String, required: true },
    external: { type: Boolean, default: false },
  },
});
</script>
