
import { defineComponent, PropType } from 'vue';
import { ICONS, useIcon } from '@/shared/composables/useAssets';

export default defineComponent({
  name: 'BaseIcon',
  props: {
    icon: {
      type: String as PropType<keyof typeof ICONS>,
      required: true,
    },
  },
  setup(props) {
    const source = useIcon(props.icon as keyof typeof ICONS);

    return {
      source,
    };
  },
});
