
import { defineComponent, PropType } from 'vue';
import Popper from 'vue3-popper';
import { useOpenable } from '@/shared/composables/useOpenable';

type BaseTooltipPlacement = 'right' | 'left' | 'bottom' | 'top';

export default defineComponent({
  name: 'BaseTooltip',
  components: { Popper },
  props: {
    placement: {
      type: String as PropType<BaseTooltipPlacement>,
      default: 'right',
    },
    iconClass: {
      type: String,
      default: 'default-icon',
    },
  },
  setup() {
    const { open, close } = useOpenable();
    return { open, close };
  },
});
