import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "truncate" }
const _hoisted_2 = { class: "multiselect-single-label" }
const _hoisted_3 = { class: "truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createBlock(_component_multiselect, {
    options: _ctx.options,
    "can-clear": false,
    onChange: _ctx.optionChange,
    placeholder: _ctx.t('base_dropdown_placeholder')
  }, {
    option: _withCtx(({ option }) => [
      _renderSlot(_ctx.$slots, "default", {
        option: option,
        label: option.label
      }, () => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(option.label), 1)
      ])
    ]),
    singlelabel: _withCtx(({ value }) => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(value.label), 1)
      ])
    ]),
    _: 3
  }, 8, ["options", "onChange", "placeholder"]))
}