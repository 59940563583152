import { App } from 'vue';

import BaseContainer from './BaseContainer/BaseContainer.vue';
import BaseText from './BaseText/BaseText.vue';
import BaseIcon from './BaseIcon/BaseIcon.vue';
import BaseImage from './BaseImage/BaseImage.vue';
import BaseButton from './BaseButton/BaseButton.vue';
import BaseHeading from './BaseHeading/BaseHeading.vue';
import BackButton from './BackButton/BackButton.vue';
import BaseInput from './BaseInput/BaseInput.vue';
import BaseTooltip from './BaseTooltip/BaseTooltip.vue';
import InputGroup from './InputGroup/InputGroup.vue';
import BaseRadioButtonGroup from './BaseRadioButtonGroup/BaseRadioButtonGroup.vue';
import BaseDropdownInput from './BaseDropdownInput/BaseDropdownInput.vue';
import IncrementInput from './IncrementInput/IncrementInput.vue';
import BaseModal from './BaseModal/BaseModal.vue';
import ProductImage from './ProductImage/ProductImage.vue';
import BaseSwitch from './BaseSwitch/BaseSwitch.vue';
import BaseTextArea from './BaseTextArea/BaseTextArea.vue';

export const globalComponents = {
  install: (app: App): App => {
    app.component('BaseContainer', BaseContainer);
    app.component('BaseText', BaseText);
    app.component('BaseIcon', BaseIcon);
    app.component('BaseImage', BaseImage);
    app.component('BaseButton', BaseButton);
    app.component('BaseHeading', BaseHeading);
    app.component('BackButton', BackButton);
    app.component('BaseInput', BaseInput);
    app.component('BaseTooltip', BaseTooltip);
    app.component('InputGroup', InputGroup);
    app.component('BaseRadioButtonGroup', BaseRadioButtonGroup);
    app.component('BaseDropdownInput', BaseDropdownInput);
    app.component('IncrementInput', IncrementInput);
    app.component('BaseModal', BaseModal);
    app.component('ProductImage', ProductImage);
    app.component('BaseSwitch', BaseSwitch);
    app.component('BaseTextArea', BaseTextArea);
    return app;
  },
};
