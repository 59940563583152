import { getConfig } from '@/shared/config';

const LM = 'lm';
const LT = 'lt';
const LV = 'lv';
const PL = 'pl';
const DE = 'de';

interface UseVersions {
  isLm: boolean;
  isLt: boolean;
  isLv: boolean;
  isPl: boolean;
  isDe: boolean;
  isMarkets: boolean;
}

export const useVersions = (): UseVersions => {
  const { appVersion } = getConfig();
  const isLm = appVersion === LM;
  const isLt = appVersion === LT;
  const isLv = appVersion === LV;
  const isPl = appVersion === PL;
  const isDe = appVersion === DE;
  const isMarkets = isLt || isLv || isPl;

  return {
    isLm,
    isLt,
    isLv,
    isPl,
    isDe,
    isMarkets,
  };
};
