import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-677e3454"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-2 lg:mb-3" }
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createVNode(_component_BaseText, {
        variant: "16-17",
        class: "mr-1"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }),
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_BaseTooltip, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.error), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}