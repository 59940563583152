
import { defineComponent } from 'vue';
import { CART_COLOR } from '@/shared/constants/header';
import { useSharedCart } from '@/pages/ProductAddToCart/composables/useCart';
import { routes } from '@/shared/constants/routes';

export default defineComponent({
  name: 'MainHeader',
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { count } = useSharedCart();

    return { count, CART_COLOR, routes };
  },
});
