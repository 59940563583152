import { AxiosResponse } from 'axios';
import { api } from '@/shared/services/api';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import { Product } from '@/shared/interfaces/product';

export class ProductDetailsService {
  async getProductDetails(id: string): Promise<AxiosResponse<ProductDetails>> {
    const requestUrl = `?id=${id}`;
    return api.get(requestUrl);
  }

  async getProductAttachments(categoryId: string): Promise<AxiosResponse<Product[]>> {
    const requestUrl = `?category=${categoryId}`;
    return api.get(requestUrl);
  }

  async getProductSuggestions(categoryId?: string): Promise<AxiosResponse<Product[]>> {
    const categoryString = categoryId ? `category=${categoryId}` : '';
    const requestUrl = `?${categoryString}`;
    return api.get(requestUrl);
  }
}
