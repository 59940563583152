import { App } from 'vue';
import {
  ProductDetailsKey,
  LmProductRequestKey,
} from '@/pages/ProductDetails/constants/injectables';
import { ProductDetailsService } from '@/pages/ProductDetails/services/productDetailsService';
import { CartRequestKey } from '@/pages/Cart/constants/injectables';
import { CartRequestService } from '@/pages/Cart/services/cartRequestService';
import { ProductsListKey } from '@/pages/ProductsList/constants/injectables';
import { ProductsListService } from '@/pages/ProductsList/services/productsListService';
import { CatalogRequestService } from '@/pages/Home/services/catalogRequestService';
import { LmRequestService } from '@/pages/Home/services/lmRequestService';
import { CatalogRequestKey, LmRequestKey } from '@/pages/Home/constants/injectables';
import { LmProductRequestService } from '@/pages/ProductDetails/services/lmProductRequestService';
import { StorageKey } from '@/shared/constants/injectables';
import { Storage } from '@/shared/services/storage';

const productDetails = new ProductDetailsService();
const cartRequest = new CartRequestService();
const productsList = new ProductsListService();
const catalogRequest = new CatalogRequestService();
const storage = new Storage();
// LM
const lmRequest = new LmRequestService();
const lmProductRequest = new LmProductRequestService();

export const composition = {
  install: (app: App): App => {
    app.provide(ProductDetailsKey, productDetails);
    app.provide(CartRequestKey, cartRequest);
    app.provide(ProductsListKey, productsList);
    app.provide(CatalogRequestKey, catalogRequest);
    app.provide(StorageKey, storage);
    // LM
    app.provide(LmRequestKey, lmRequest);
    app.provide(LmProductRequestKey, lmProductRequest);
    return app;
  },
};
