import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Disclaimer = _resolveComponent("Disclaimer")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { variant: _ctx.variant }, null, 8, ["variant"]),
    (!_ctx.isLm)
      ? (_openBlock(), _createBlock(_component_Disclaimer, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_Footer)
  ], 64))
}