import { AxiosResponse } from 'axios';
import { api } from '@/shared/services/api';
import { TransformedCartRequest } from '@/shared/interfaces/cart';

export class CartRequestService {
  async requestCart(values: TransformedCartRequest): Promise<AxiosResponse<Response>> {
    const requestUrl = '/book-products';
    return api.post(requestUrl, values);
  }
}
