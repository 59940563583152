interface StylesType {
  sizes: Record<string, string>;
  variants: { [key: string]: Record<string, string> };
}
interface PropsType {
  variant?: string;
  color?: string;
  size?: string | number;
}

export const mergeClasses = (styles: StylesType, props: PropsType): string[] => {
  const { variants, sizes } = styles;
  const { variant, color, size } = props;

  const classes: string[] = [];

  if (size) {
    classes.push(sizes[size]);
  }
  if (variant !== 'none' && variant) {
    classes.push(variants[variant].base);
  }
  if (color !== 'none' && variant && color) {
    classes.push(variants[variant][color]);
  }

  return classes.filter(Boolean);
};
