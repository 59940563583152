import { vModelText as _vModelText, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51cd2a12"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "search-clear",
  type: "reset"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_HeaderLink = _resolveComponent("HeaderLink")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          class: "search",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["prevent"])),
          onReset: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
        }, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["search-input", { 'input-gray': _ctx.isGray }]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
          }, null, 2), [
            [_vModelText, _ctx.searchValue],
            [_directive_focus]
          ]),
          _withDirectives(_createElementVNode("button", _hoisted_1, [
            _createVNode(_component_BaseIcon, {
              icon: "close",
              class: _normalizeClass({ 'variant-gray': _ctx.isGray })
            }, null, 8, ["class"])
          ], 512), [
            [_vShow, _ctx.searchValue]
          ])
        ], 32))
      : (!_ctx.isMarkets)
        ? (_openBlock(), _createElementBlock("nav", {
            key: 1,
            class: _normalizeClass(["nav-container", { 'variant-gray': _ctx.isGray }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerLinks, (link) => {
              return (_openBlock(), _createBlock(_component_HeaderLink, {
                key: link.term,
                path: link.path,
                external: link.external,
                class: "nav-link"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseText, {
                    class: _normalizeClass(["nav-text", { 'nav-underline': !link.external }])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t(`${link.term}`)), 1)
                    ]),
                    _: 2
                  }, 1032, ["class"])
                ]),
                _: 2
              }, 1032, ["path", "external"]))
            }), 128))
          ], 2))
        : _createCommentVNode("", true),
    _createVNode(_component_BaseIcon, {
      icon: "search",
      class: _normalizeClass(["search-icon", { 'variant-gray': _ctx.isGray }]),
      onClick: _ctx.toggle
    }, null, 8, ["class", "onClick"])
  ], 64))
}