import { Ref, shallowRef } from 'vue';
import { getConfig } from '@/shared/config';

export type SvgImageImportType = typeof import('*.svg');
export type ImageImportType = typeof import('*.png') | SvgImageImportType;

interface IconsType<T> {
  [name: string]: () => Promise<T>;
}

export const IMAGES: IconsType<ImageImportType> = {
  logo: () => import('@/assets/logo.svg'),
  logo_small: () => import('@/assets/logo_small.svg'),
  logo_white: () => import('@/assets/logo_white.svg'),
  logo_small_white: () => import('@/assets/logo_small_white.svg'),
  logo_black: () => import('@/assets/logo_black.svg'),
  home_logo: () => import('@/assets/home_logo.png'),
  home_logo_lm: () => import('@/assets/home_logo_lm.png'),
  catalog_logo_1: () => import('@/assets/catalog_logo_1.png'),
  catalog_logo_2: () => import('@/assets/catalog_logo_2.png'),
  catalog_logo_3: () => import('@/assets/catalog_logo_3.png'),
  lm_request_logo: () => import('@/assets/lm_request_logo.png'),
  new_product: () => import('@/assets/new_product.png'),
  product_default_thumbnail: () => import('@/assets/product_default_thumbnail.png'),
  scroll_up: () => import('@/assets/ic_scroll_up.svg'),
};

export const ICONS: IconsType<SvgImageImportType> = {
  facebook: () => import('@/assets/ic_facebook.svg?inline'),
  instagram: () => import('@/assets/ic_instagram.svg?inline'),
  linkedin: () => import('@/assets/ic_linkedin.svg?inline'),
  menu: () => import('@/assets/ic_menu.svg?inline'),
  close: () => import('@/assets/ic_close.svg?inline'),
  search: () => import('@/assets/ic_search.svg?inline'),
  arrow: () => import('@/assets/ic_arrow.svg?inline'),
  arrow_thick: () => import('@/assets/ic_arrow_thick.svg?inline'),
  plus: () => import('@/assets/ic_plus.svg?inline'),
  check: () => import('@/assets/ic_check.svg?inline'),
  error: () => import('@/assets/ic_error.svg?inline'),
  arrow_down: () => import('@/assets/ic_arrow_down.svg?inline'),
  stepper_minus: () => import('@/assets/ic_stepper_minus.svg?inline'),
  stepper_plus: () => import('@/assets/ic_stepper_plus.svg?inline'),
  catalog_new_logo: () => import('@/assets/catalog_new_logo.svg?inline'),
  mini_excavator: () => import('@/assets/ic_mini_excavator.svg?inline'),
  excavator: () => import('@/assets/ic_excavator.svg?inline'),
  loader: () => import('@/assets/ic_loader.svg?inline'),
  caterpillars: () => import('@/assets/ic_caterpillars.svg?inline'),
  dumper: () => import('@/assets/ic_dumper.svg?inline'),
  compression: () => import('@/assets/ic_compression.svg?inline'),
  transfer: () => import('@/assets/ic_transfer.svg?inline'),
  equipment: () => import('@/assets/ic_equipment.svg?inline'),
  site_equipment: () => import('@/assets/ic_site_equipment.svg?inline'),
  cleaning: () => import('@/assets/ic_cleaning.svg?inline'),
  attachments: () => import('@/assets/ic_attachments.svg?inline'),
  next: () => import('@/assets/ic_next.svg?inline'),
  cart: () => import('@/assets/ic_cart.svg?inline'),
  trailer: () => import('@/assets/ic_trailer.svg?inline'),
  compaction: () => import('@/assets/ic_compaction.svg?inline'),
  screening: () => import('@/assets/ic_screening.svg?inline'),
  garden: () => import('@/assets/ic_garden.svg?inline'),
};

const imagesBaseUrl = getConfig().imagesBaseUrl || 'https://www.kurt-koenig.de/';

export const useImage = (iconName: keyof typeof IMAGES): Ref<unknown> => {
  const icon = shallowRef<unknown>(null);
  if (IMAGES[iconName]) {
    IMAGES[iconName]().then((res: ImageImportType) => {
      icon.value = res.default;
    });
  }
  return icon;
};

export const useIcon = (iconName: keyof typeof ICONS): Ref<unknown> => {
  const icon = shallowRef<unknown>(null);
  if (ICONS[iconName]) {
    ICONS[iconName]().then((res: SvgImageImportType) => {
      icon.value = res.default;
    });
  }
  return icon;
};

export const useProductImage = (url: string): string => `${imagesBaseUrl}${url}`;
